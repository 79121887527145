

$primary-color:#2b4570;

.margin-class{
    margin: 4px;
}

.banko-button{
    padding: 6px 12px 6px 12px;
    border-radius: 5px;
    border:none;
    outline: none;
    cursor: pointer;
    box-shadow:  0px 8px 23px rgba(0, 0, 0, 0.08);
    background-color: $primary-color;
    color: white;
    @extend .margin-class;
}

.banko-input{
    padding: 4px;
    border-radius: 5px;
    outline: none;
    @extend .margin-class;
}

.banko-select{
    height: 28px;
    border-radius: 5px;
    @extend .margin-class;
}

#bankoNavbar{
    box-shadow:  0px 8px 23px rgba(0, 0, 0, 0.08);
    padding: 20px;
    margin: 20px;
}


#bankoContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 100px 20px 100px;

    #bankoplade{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    table.banko-table {
        border: 1px solid black;
        margin: 8px;
        border-spacing: 0;
        text-align: center;
        td {
            border: 1px solid black;
            height: 58px;
            width: 58px;
        }
    }
}

@media print {



    #bankoContainer{
       margin: 0 !important;
       padding: 0 !important;
    }

    #bankoNavbar{
        display: none !important;
    }

    table.banko-table {
      
        &.six-tables-layout{
          

            margin-left: 1px !important;
            margin-right: 1px !important;
            margin-bottom: 60px !important;
            width: 49vw !important;
        }

        &.three-tables-layout{
           
            margin-bottom: 80px !important;
          
        }
      
    }
 }